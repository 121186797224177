
.summary-table-container {
    padding: 28px 0;
}

.summary-table {
    --border: 1px solid var(--color-violet-400);
    border-top: var(--border);
    border-bottom: var(--border);
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    .summary-table-cell {
        padding: 0 8px;
        flex-grow: 1;
        flex-basis: 50%;
        text-align: center;

        &:first-child {
            border-right: var(--border);
        }

        &:last-child {
            border-left: var(--border);
        }
    }

    .cell-title {
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 700;
        letter-spacing: .24px;
        color: var(--color-violet-100);
        margin-bottom: 15px;
    }

    .cell-value {
        font-weight: 700;
        color: var(--color-green-300);
        margin-bottom: 15px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.summary-table-title {
    text-transform: uppercase;
    color: var(--color-shades-100);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-align: center;
}
