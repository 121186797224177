@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/static/icons/icomoon.eot?bzqugg');
  src:  url('../assets/fonts/static/icons/icomoon.eot?bzqugg#iefix') format('embedded-opentype'),
    url('../assets/fonts/static/icons/icomoon.ttf?bzqugg') format('truetype'),
    url('../assets/fonts/static/icons/icomoon.woff?bzqugg') format('woff'),
    url('../assets/fonts/static/icons/icomoon.svg?bzqugg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-arr-left:before {
  content: "\e900";
}
.icon-icon-arr-right:before {
  content: "\e901";
}
.icon-icon-bandage:before {
  content: "\e902";
}
.icon-icon-bell:before {
  content: "\e903";
}
.icon-icon-beskeder:before {
  content: "\e904";
}
.icon-icon-brain:before {
  content: "\e905";
}
.icon-icon-calendar:before {
  content: "\e906";
}
.icon-icon-graph-2:before {
  content: "\e907";
}
.icon-icon-graph:before {
  content: "\e908";
}
.icon-icon-heart:before {
  content: "\e909";
}
.icon-icon-lotus:before {
  content: "\e90a";
}
.icon-icon-men:before {
  content: "\e90b";
}
.icon-icon-night:before {
  content: "\e90c";
}
.icon-icon-period:before {
  content: "\e90d";
}
.icon-icon-play:before {
  content: "\e90e";
}
.icon-icon-scale:before {
  content: "\e90f";
}
.icon-icon-settings:before {
  content: "\e910";
}
.icon-icon-show:before {
  content: "\e911";
}
.icon-icon-stats-line-charts:before {
  content: "\e912";
}
.icon-icon-trash:before {
  content: "\e913";
}
.icon-icon-triangle:before {
  content: "\e914";
}
.icon-icon-workload:before {
  content: "\e915";
}
.icon-women:before {
  content: "\e916";
}
