@use "sass:math";

.display-01 {
    font-size: 50px;
    line-height: math.div(60, 50);
}

.display-02 {
    font-size: 120px;
    line-height: math.div(146, 20);
}
