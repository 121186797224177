@use "sass:math";

.text-center{
     text-align: center;
}

.ion-extra-light {
    font-weight: 200;
}

.ion-light {
    font-weight: 300;
}

.ion-regular {
    font-weight: 400;
}

.ion-medium {
    font-weight: 500;
}

.ion-semi-bold {
    font-weight: 600;
}

.ion-bold {
    font-weight: 700;
}

.ion-extra-bold {
    font-weight: 800;
}

.ion-text-lowercase{
    text-transform: lowercase;
}

.ion-text-uppercase{
    text-transform: uppercase;
}

h1, .h1 {
    font-size: 34px;
    line-height: math.div(42, 34);
}

h2, .h2 {
    font-size: 30px;
    line-height: math.div(36, 30);
}

h3, .h3 {
    font-size: 26px;
    line-height: math.div(32, 26);
}

h4, .h4 {
    font-size: 24px;
    line-height: math.div(29, 24);
}

h5, .h5 {
    font-size: 21px;
    line-height: math.div(25, 21);
}

h6, .h6 {
    font-size: 18px;
    line-height: math.div(22, 18);
}

p {
    font-size: 16px;
    line-height: math.div(19, 16);
}

.font-small {
    font-size: 14px;
    line-height: math.div(21, 14);
}

.font-ex-small {
    font-size: 10px;
    line-height: math.div(14, 10);
}

.form-title {
    color: var(--color-green-300);
    font-size: 21px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    margin-bottom: 18px;
}


.page-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.17;
    color: var(--color-green-300);
    margin-bottom: 24px;
    margin-top: 0;
    text-align: center;
}

.page-descr {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: var(--color-neutral-500);
}
