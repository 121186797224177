.slide-item {
    .slide-media, .media, video, iframe, picutre, img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.slide-title {
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    line-height: var(--title-line-height);
    color: var(--title-color);
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0 var(--padding) 10px var(--padding);
}

.slide-description {
    font-size: var(--descr-size);
    font-weight: var(--descr-weight);
    line-height: var(--descr-line-height);
    color: var(--descr-color);
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0 var(--padding) var(--padding) var(--padding);
}
