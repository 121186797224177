@use "sass:math";

.units {
    font-size: 12px;
    line-height: math.div(16, 12);
}

.w-full {
    width: 100%;
    display: block;
}
