///*
//* App Global CSS
//* ----------------------------------------------------------------------------
//* Put style rules here that you want to apply globally. These styles are for
//* the entire app and not just one components. Additionally, this file can be
//* used as an entry point to import other CSS/Sass files to be included in the
//* output CSS.
//* For more information on global stylesheets, visit the documentation:
//* https://ionicframework.com/docs/layout/global-stylesheets
//*/
//
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~keen-slider/keen-slider.min.css';

@import "theme/fonts";

//Icomoon css
@import "theme/icomoon.css";

//Font awesome usage example with extend
//https://fontawesome.com/v5/docs/web/use-with/sass
@import "assets/fontawesome_scss/fontawesome";
@import "assets/fontawesome_scss/solid";

@import "theme/controls/controls";

@import "theme/typography/data-x-small";
@import "theme/typography/display";
@import "theme/typography/drop-list";
@import "theme/typography/score-card";
@import "theme/typography/units";
@import "theme/typography/typography";

@import "theme/compoenents/collector";
@import "theme/compoenents/components";
@import "theme/compoenents/metric-statistic";
@import "theme/compoenents/swiper-item-elements";

/* angular-cli file: src/styles.css */
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";


:root {
    --font-family: 'Inter', 'Roboto', 'HelveticaNeue', 'Helvetica Neue', sans-serif;
    --font-icon: 'icomoon';
    --font-awesome: 'Font Awesome 5 Free';

    --default-font-size: 16px;
    --default-font-weight: 400;
    --default-line-height: 1.25;
    --default-color: #FFFFFF;
    --default-white: #FFFFFF;

    --color-neutral-50: #000000;
    --color-neutral-100: #D9D9D9;
    --color-neutral-200: #C0C0C0;
    --color-neutral-300: #818192;
    --color-neutral-400: #716C7D;
    --color-neutral-500: #8382A6;
    --color-neutral-600: #74698F;
    --color-neutral-700: #53486D;
    --color-neutral-800: #403A52;
    --color-neutral-900: #13082B;


    --color-green-100: #E3FDF7;
    --color-green-200: #A5FAE5;
    --color-green-300: #2AF8D1;
    --color-green-400: #08CFC3;
    --color-green-500: #0592A5;

    --color-blue-100: #9DB3FF;
    --color-blue-200: #4870FF;
    --color-blue-300: #221FC9;
    --color-blue-400: #121098;
    --color-blue-500: #1B0058;

    --color-violet-100: #B2ADBC;
    --color-violet-200: #564187;
    --color-violet-300: #3C129A;
    --color-violet-400: #322654;
    --color-violet-500: #281B47;


    --color-highlight-100: #E5AE1A;
    --color-highlight-200: #D15911;
    --color-highlight-300: #B11251;
    --color-highlight-400: #9670EB;
    --color-highlight-500: #00A1D3;

    --color-gradient-100-1: #3C129A;
    --color-gradient-100-2: #2AF8D1;
    --color-gradient-100: linear-gradient(149.34deg, var(--color-gradient-100-1) 16.59%, var(--color-gradient-100-2) 79.94%);

    --color-gradient-200-1: #3DC5FF;
    --color-gradient-200-2: #2AF8D1;
    --color-gradient-200: linear-gradient(149.34deg, var(--color-gradient-200-1) 16.59%, var(--color-gradient-200-2) 79.94%);

    --color-gradient-300-1: rgba(0, 0, 0, 0);
    --color-gradient-300-2: rgba(0, 0, 0, 0, .5);
    --color-gradient-300: linear-gradient(180deg, var(--color-gradient-300-1) 33.76%, var(--color-gradient-300-2) 81.82%);

    --color-gradient-400-1: #221938;
    --color-gradient-400-2: #13082B;
    --color-gradient-400: linear-gradient(180deg, var(--color-gradient-400-1) 0%, var(--color-gradient-400-2) 100%);

    --color-shades-100: #FFFFFF;
    --color-shades-200: #000000;
    --color-shades-300: rgba(55, 48, 75, 0.7);

    --background-color-100: #221938;
    --background-color-200: #2C2441;
    --background-color-300: #361F67;

    --color-warning-100: #E9225E;

    --shadow-d100: 0px -2px 4px rgba(0, 0, 0, 0.15);
    --shadow-d200: 0px -5px 12px rgba(0, 0, 0, 0.2);
    --shadow-d300: 0px 3px 12px rgba(0, 0, 0, 0.15);
    --shadow-d400: 0px 0px 50px rgba(0, 0, 0, 0.102);
    --shadow-i100: 0px 3px 8px rgba(0, 0, 0, 0.15);

    --text-control-default: 14px;
    --py-control-default: 14px;
    --px-control-default: 14px;

    --rounded-control-default: 12px;

}

body {
    font-family: var(--font-family);
}

//Global ion overrides
ion-header {
    &:after {
        display: none;
    }
}

.page-container {
    padding: 0 34px 0 34px;
}

.transparent-modal {
    --background: transparent;

    ion-content {
        --background: transparent;
    }

    .modal-handle {
        display: none;
    }

    .mobile-app-customization {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-violet-500);
        padding: 20px 20px 40px;
        border-radius: 30px 30px 0 0;
        max-height: 90%;
        overflow: scroll;
    }

    .modal-custom-hide {
        transform: translate3d(0, 0, 0);
        transition: all .3s ease-out;
    }

    .modal-hidden {
        transform: translate3d(0, 100%, 0);
    }

    .modal-custom-show {
        transform: translate3d(0, 100%, 0);
        transition: all .3s ease-out;
    }

    .modal-shown {
        transform: translate3d(0, 0, 0);
    }
}

.ion-page {
    .transparent-modal {
        .modal-hidden-finished {
            visibility: hidden;

            .mobile-app-customization {
                display: none;
            }
        }
    }
}

.custom-modal-hider > * {
    display: none !important;
    visibility: hidden !important;
}

.customization-hidden {
    display: none !important;
    visibility: hidden !important;
}

.pdn-top30 {
    padding-top: 30px !important;
}

.header-md {
    box-shadow: initial !important;
}

.padding-from-bottom {
    padding-bottom: 130px;
}
