.sub-grid {
    padding-left: 0;
    padding-right: 0;
}

.buttons-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;

    ion-button {
        margin: 0 0 20px 0;
    }

    .cancel-btn {
        margin-top: 4px;
    }
}
